<template>
  <div class="auth-wrapper auth-v2 slidercontainer">
    <b-row class="auth-inner m-0">
      <b-col lg="8" class="d-none d-lg-flex align-items-center slideritem">
        <div class="banneritem d-none">
          <div class="logoitem">
            <img src="@/assets/images/premium-tka.svg" />
          </div>
        </div>
        <b-carousel img-height="480" controls indicators>
          <b-carousel-slide img-src="@/assets/images/slider-1.png" />
          <b-carousel-slide img-src="@/assets/images/slider-2.png" />
          <b-carousel-slide img-src="@/assets/images/slider-3.png" />
          <b-carousel-slide img-src="@/assets/images/slider-4.png" />
        </b-carousel>
      </b-col>
      <b-col
        lg="4"
        class="d-flex flex-column justify-content-center auth-bg contentitem"
        style="background-color: #121415"
      >
        <div class="logoimg">
          <img
            src="@/assets/images/premium-tka.svg"
            width="320"
            class="d-none"
            alt=""
          />
        </div>
        <div
          class="panel-mini panel-devicerequest card"
          style="
            border: none !important;
            box-shadow: none;
            background-color: #121415;
            border: 1px solid #494946;
          "
        >
          <img
            src="@/assets/images/premium-tka-white.svg"
            width="320"
            class="mt-2"
            alt=""
          />
          <div class="card-header">
            <h3 class="text-white fw-600 text-uppercase">
              Teknik Servis Takibi
            </h3>
            <div class="text-white">
              Servisteki ürününüzün durumunu takip etmek veya online ödeme
              yapmak takip numaranızı giriniz.
            </div>
          </div>
          <div class="card-body" style="background-color: #121415 !important">
            <div
              v-if="datasource.alert.text && datasource.alert.text.length > 0"
              :class="datasource.alert.variant"
              class="alert p-1"
            >
              {{ datasource.alert.text }}
            </div>
            <div v-if="datasource.panel_request_show == true" class="mt-2">
              <div>
                <div class="form-group fw-600">
                  <label class="text-uppercase text-white" for="sfno"
                    >Servis Takip Numarası</label
                  >
                  <input
                    v-model="devicefind.request.sf_no"
                    id="sfno"
                    class="form-control text-uppercase form-control-lg"
                    type="text"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group fw-600">
                  <label class="text-uppercase text-white" for="sfgsm"
                    >GSM No</label
                  >
                  <VuePhoneNumberInput
                    size="lg"
                    :translations="datasource.phonetranslations"
                    v-model="devicefind.request.gsm"
                  />
                </div>

                <div class="form-group mt-3">
                  <button
                    @click="checkServiceForm()"
                    class="btn bg-basered text-white text-uppercase btn-block"
                  >
                    Sorgula
                  </button>
                </div>
              </div>
            </div>
            <div v-if="datasource.panel_verify_show == true" class="mt-2">
              <div class="form-group text-white">
                <b>{{ devicefind.request.gsm }}</b>
                telefon numaranıza gönderilen doğrulama kodunu yazın.
              </div>
              <div class="form-group fw-600">
                <label class="text-uppercase text-white" for="sfno"
                  >Doğrulama Kodu</label
                >
                <input
                  v-model="devicefind.request.code"
                  id="sfno"
                  class="form-control form-control-lg"
                  type="text"
                  autocomplete="off"
                />
              </div>
              <div class="form-group mt-3">
                <div class="row">
                  <div class="col-sm-7">
                    <button
                      @click="checkOTPCode()"
                      class="text-uppercase bg-basered text-white btn btn-block"
                    >
                      Doğrula
                    </button>
                  </div>
                  <div class="col-sm-5">
                    <button class="btn text-white btn-link">
                      Yeniden Gönder (<countdown-plus
                        format="mm:ss"
                        :time="3 * 60 * 1000"
                      />)
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import CountdownPlus from "vue-countdown-plus";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    CountdownPlus,
    BCarousel,
    BCarouselSlide,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
      email,
      datasource: {
        alert: { text: "", variant: "danger" },
        panel_request_show: true,
        panel_verify_show: false,
        phonetranslations: {
          countrySelectorLabel: "Ülke",
          countrySelectorError: "Ülke Seçin",
          phoneNumberLabel: "GSM Numaranız",
          example: "Örnek :",
        },
      },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    ...mapState({
      devicefind: (state) => state.devicefind,
    }),
  },
  methods: {
    ...mapActions(["requestServiceForm", "verifyOTPCodeServiceForm"]),
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },
    checkServiceForm() {
      this.requestServiceForm().then((q) => {
        this.datasource.alert.text = "";
        if (q.success == false) {
          this.datasource.alert.text = q.message;
          this.datasource.alert.variant = "alert-danger";
        } else {
          this.datasource.panel_request_show = false;
          this.datasource.panel_verify_show = true;
        }
      });
    },
    checkOTPCode() {
      this.verifyOTPCodeServiceForm().then((q) => {
        this.datasource.alert.text = "";

        if (q.success == false) {
          this.datasource.alert.text = q.message;
          this.datasource.alert.variant = "alert-danger";
        } else {
          this.devicefind.customer = q.data.customer;
          this.devicefind.customer_device_request =
            q.data.customer_device_request;
          this.devicefind.devices = q.data.devices;
          this.devicefind.invoice_item = q.data.invoice_item;
          this.devicefind.payments = q.data.payments;
          this.devicefind.customer_informations = q.data.customer_informations;
          this.devicefind.sf = q.data.sf;
          let current = this.devicefind.devices[0];
          this.devicefind.current_device = current;

          this.$router.push({ name: "home" });
        }
      });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/pages/page-auth.scss";
.carousel-item img {
  height: 100vh !important;
  object-fit: unset;
}
@media screen and (max-width: 576px) {
  .logoimg {
    text-align: center;
    img {
      margin: 0px;
      margin: auto;
    }
  }
}
.bg-white {
  background-color: #fff !important;
}
.slidercontainer {
  background: #121414;
}
</style>